import {
  Component,
  Input,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ServerService } from '../../../services/server.service';
import { MessageService } from 'primeng/api';
import { Indicator } from 'src/app/models/indicator';
import { concatMap, of } from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { InputExcelComponent } from 'src/app/components/input-excel/input-excel.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-auto-form-viewer',
  templateUrl: './auto-form-viewer.component.html',
  styleUrls: ['./auto-form-viewer.component.scss'],
})
export class AutoFormViewerComponent implements OnInit {
  @Input() jsonData: any;
  @Input() indicatorId?: number = 0;
  @Input() indicatorName?: string = '';
  @Input() indicator?: Indicator;
  @Input() completingForm?: boolean = false;
  @Input() formType?: string = '';
  @Input() initialStateId?: number = 0;
  @Input() formObjectives?: any;
  @Input() formEvidence?: any;
  @Input() actionId?: number;
  @Input() preview?: boolean;
  @Input() evidenceDocument?: File;
  @Output() completedDynamicObjectiveForm = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  @Input() value: any;
  @ViewChildren('operationButton') operationButtons!: QueryList<ElementRef>;
  @Output() valueChange = new EventEmitter<any>();
  @Input() required: boolean = false; // Agregar atributo requerido
  jsonResult: any = {};
  labels: any = [];
  dataLoaded: boolean = false;
  nivel: number = 0;
  previousKey: string | null = null;
  inputValues: number[] = [];
  inputValuesForm = {};
  loadingModal: boolean = false;
  loadingForm: boolean = false;
  defaultField: string | null = null;
  unitsData: any[] = [];
  selectedUnit: any | null = null;
  defaultFieldValue: number | null = null;
  // todas las instancias de input Excel las guardo aqui
  @ViewChildren(InputExcelComponent)
  inputComponents!: QueryList<InputExcelComponent>;

  constructor(
    private serverService: ServerService,
    private messageService: MessageService,
    private router: Router,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getUnitsData();
    this.getAutoFormData();
  }

  getUnitsData(){
    this.serverService.getData(`/api/unit-types`).subscribe({
      next: (response) => {
        this.unitsData = response.data
      },
      error: (err) => {
        console.error('Error al obtener datos de unidades XBRL', err);
      }
    })
  }

  /**
   * Función para eliminar comillas de un string
   * @param str
   * @returns
   */
  removeQuotes(str: string) {
    if (str.startsWith('"') && str.endsWith('"')) {
      return str.slice(1, -1);
    }
    return str;
  }

  /**
   * Comprobación de que todos los campos están completados del formulario
   * @returns
   */
  areAllFieldsFilled(): boolean {
    let allFieldsFilled = true;
    this.inputComponents.forEach((component) => {
      if (!component.isValid()) {
        allFieldsFilled = false;
      }
    });
    return allFieldsFilled;
  }

  /**
   * Procesado de archivo JSON para generar formulario dinámico
   */
  getAutoFormData() {
    const deepCopyData = JSON.parse(JSON.stringify(this.jsonData));
    this.jsonResult = deepCopyData;
    this.labels = this.jsonData.labels;
    delete this.jsonData.labels;
    this.dataLoaded = true;
    // Obtenemos el campo por defecto para definir su valor, debe ser tipo numérico
    if (this.preview) {
      this.getDefaultField();
    }
  }

  /**
   * Función auxiliar para obtener campo que actuará como campo por defecto, el cual contiene el caracter #, asi como comprobar que sea un campo numérico
   */
  getDefaultField() {
    // Obtenemos el primer nivel dinámicamente
    const level1: any = Object.values(this.jsonData)[0];

    // Recorremos las propiedades del objeto Nivel1
    for (const key in level1) {
      if (level1.hasOwnProperty(key)) {
        // Verificamos si la clave contiene el carácter '$'
        if (key.includes('$') && level1[key].tipo === 'number') {
          this.defaultField = key;
          return key; // Retornamos el nombre del campo que cumple con las condiciones
        }
      }
    }

    this.defaultField = null;
    return null;
  }

  handleKey(event) {
  }

  /**
   * Función recursiva para añadir la propiedad checked a cada objeto con la propiedad tipo
   * @param data
   */
  addCheckedProperty(data: any) {
    for (const key of Object.keys(data)) {
      if (typeof data[key] === 'object' && data[key] !== null) {
        if ('tipo' in data[key]) {
          data[key].checked = false;
        } else {
          this.addCheckedProperty(data[key]);
        }
      }
    }
  }

  /**
   * 
   * @param data
   * @returns
   */
  getKeys(data: any): string[] {
    return Object.keys(data);
  }

  /**
   *
   * @returns
   */
  increaseLevel() {
    return this.nivel++;
  }

  /**
   * Limpiar terminación "campo", "$" y "&" de los títulos
   * @param key
   * @returns
   */
removeCampoSuffix(key: string): SafeHtml {
  let suffix = '';

  const hasDollar = key.includes('$');
  const hasAmpersand = key.includes('&');
  const hasHash = key.includes('#');

  if (hasDollar && hasAmpersand && hasHash) {
    suffix = ' <span style="font-weight: bold;">(campo usado para valor defecto, gráficas y reporte XBRL)</span>';
  } else if (hasAmpersand && hasDollar) {
    suffix = ' <span style="font-weight: bold;>(campo usado para valor defecto y gráficas)</span>';
  } else if (hasAmpersand && hasHash) {
    suffix = ' <span style="font-weight: bold;">(campo usado para gráficas y reporte XBRL)</span>';
  } else if (hasDollar && hasHash) {
    suffix = ' <span style="font-weight: bold;">(campo usado para valor defecto y reporte XBRL)</span>';
  } else if (hasAmpersand) {
    suffix = ' <span style="font-weight: bold;">(campo usado para valores de gráficas)</span>';
  } else if (hasDollar) {
    suffix = ' <span style="font-weight: bold;">(campo usado para valor defecto)</span>';
  } else if (hasHash) {
    suffix = ' <span style="font-weight: bold;">(campo usado para reporte XBRL)</span>';
  }

  key = key.replace(/[$&#]/g, '');

  if (key.includes('_campo')) {
    key = key.split('_campo')[0];
  }

  return this.sanitizer.bypassSecurityTrustHtml((key + suffix).trim());
}

  removeCampoSuffixDefaultField(key: string): string {
    if (key.includes('$')) {
      key = key.replace('$', ''); // Elimina el símbolo #
    }

    if (key.includes('_campo')) {
      key = key.split('_campo')[0];
    }

    return key.trim(); // Añade el sufijo y elimina cualquier espacio en blanco adicional
  }

  /**
   *
   * @param data
   * @returns
   */
  typeHasSuffix(data) {
    const parts = data.split('_');
    const coletilla = parts[1];

    return coletilla !== undefined ? coletilla : '';
  }

  /**
   *
   * @param data
   * @returns
   */
  deleteSuffix(data) {
    const parts = data.split('_');
    const type = parts[0];

    return type;
  }

  /**
   *
   * @param str
   * @returns
   */
  extractNumber(str: string): number {
    const match = str.match(/\d+/)?.[0];
    return parseInt(match || '0', 10);
  }

  /**
   *
   * @param str
   * @returns
   */
  extractOperation(str: string): string {
    const match = str.match(/^[^(]+/)?.[0] || '';
    return match.trim();
  }

  /**
   * Funcón para copiar jsonData en jsonResult con propiedad checked si la hubiera
   * @param copia1
   * @param copia2
   */
  dataCopyToResult(copy1: any, copy2: any) {
    // Obtener el nombre dinámico del objeto Header
    const headerKey = Object.keys(copy1)[0];

    // Verificar que copia1 y copia2 tengan el mismo headerKey
    if (headerKey && copy1[headerKey] && copy2[headerKey]) {
      copy2[headerKey] = { ...copy1[headerKey] };
    }
  }

  /**
   * Enviar modelo del formulario procedente de un Excel (se envia como JSON a BBDD)
   */
  sendData() {
    if (this.defaultField && !this.defaultFieldValue) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debes introducir un valor para el campo por defecto',
      });
    }

    if(!this.selectedUnit){
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debes seleccionar una unidad de medida para el reporte XBRL',
      });
    }

    const jsonDataCopy = JSON.parse(JSON.stringify(this.jsonData));
    this.dataCopyToResult(jsonDataCopy, this.jsonResult);
    const dataConfig = { 
      config: JSON.stringify(this.jsonResult),
      unit_type_id: this.selectedUnit
    };

    this.loadingForm = true;

    this.serverService
      .sendData('/api/indicator-configurations', dataConfig)
      .pipe(
        concatMap((response) => {
          // Me aseguro que la segunda llamada se hace si y solo si la primera acaba con concatMap
          if (response.data) {
            const dataIndicator: any = {
              name: this.indicatorName,
              indicator_configuration_id: response.data.id,
              firstTime: true,
            };

            // Si se ha definido campo por defecto, añadimos el valor y la etiqueta al indicador
            if (this.defaultField) {
              dataIndicator.default_field_value = this.defaultFieldValue;
              dataIndicator.default_field_label = this.defaultField;
            }

            return this.serverService.updateData(
              '/api/indicators/' + this.indicatorId,
              dataIndicator
            );
          }
          return of(null, (this.loadingForm = false)); // Si response.data es vacio, devolvemos null
        })
      )
      .subscribe({
        next: (response) => {
          if (response) {
            // Indicador actualizado con referencia de indicador_configuration ==> Vamos a detalle de indicador
            this.router.navigate([`base-indicator/${this.indicatorId}`]);
            this.preview = false;
            this.loadingForm = false;
          }
        },
        error: (err) => {
          console.error('Error en el proceso de actualizar indicador:', err);
          this.loadingForm = false;
        },
      });
  }
}
