import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AutoFormViewerComponent } from '../pages/import-excel/auto-form-viewer/auto-form-viewer.component';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowDynamicFormDataComponent } from './show-dynamic-form-data/show-dynamic-form-data.component';
import { InputExcelComponent } from './input-excel/input-excel.component';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { ModalOptionsComponent } from './modal-options/modal-options.component';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    AutoFormViewerComponent,
    ShowDynamicFormDataComponent,
    InputExcelComponent,
    ModalOptionsComponent,
  ],
  imports: [
    IonicModule,
    MessagesModule,
    InputTextModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    ButtonModule,
    CommonModule,
  ],
  exports: [
    AutoFormViewerComponent,
    ShowDynamicFormDataComponent,
    InputExcelComponent,
    ModalOptionsComponent,
  ],
  providers: [DatePipe],
})
export class ComponentsModule {}
