import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-show-dynamic-form-data',
  templateUrl: './show-dynamic-form-data.component.html',
  styleUrls: ['./show-dynamic-form-data.component.scss'],
})
export class ShowDynamicFormDataComponent implements OnInit {
  @Input() data?: any;
  @Output() formValuesChange = new EventEmitter<any>();
  dynamicForm: FormGroup;
  fieldGroups: any[] = [];
  rootObjectKey: any;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  /**
   * Cada vez que cambien los datos de entrada, entramos por aquí
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.buildForm();
    }
  }

  /**
   * Procesamientio de datos de data para formar el formulario dinámico de edición
   * @returns
   */
  private buildForm() {
    if (!this.data) {
      return;
    }

    this.fieldGroups = [];
    const formGroup = {};

    // Se acepta un subgrupo por ahora
    // Obtener el objeto principal dinámicamente (Residuos por ejemplo)
    this.rootObjectKey = Object.keys(this.data.data)[0];
    const mainObject: any = Object.values(this.data.data)[0];

    // Iterar sobre las propiedades del objeto principal
    for (const key in mainObject) {
      if (mainObject.hasOwnProperty(key)) {
        const subObject = mainObject[key];
        const groupControls = {};

        for (const subKey in subObject) {
          if (
            subObject.hasOwnProperty(subKey) &&
            subKey !== 'tipo' &&
            subKey !== 'checked'
          ) {
            const controlName = `${key}_${subKey}`;
            formGroup[controlName] = new FormControl(subObject[subKey]);
            groupControls[subKey] = controlName;
          }
        }

        // Agregar a la lista de grupos de campos para renderizar
        this.fieldGroups.push({
          groupName: key,
          controls: groupControls,
          tipo: subObject['tipo'],
        });
      }
    }

    this.dynamicForm = this.fb.group(formGroup);
    // Suscribir a los cambios en el formulario dynamicForm para emitir los valores actualizado
    // Cada vez que el formulario cambie, se emite evento de vuelta al padre por medio de emitFormValues
    this.dynamicForm.valueChanges.subscribe(() => this.emitFormValues());
  }

  /**
   * Función para extraer operación aritmética del formulario Excel
   * @param str
   * @returns
   */
  extractOperation(str: string): string {
    const match = str.match(/^[^(]+/)?.[0] || '';
    return match.trim();
  }

  /**
   * Manejador para emitir el evento al componente padre con datos del formulario
   */
  emitFormValues() {
    const values = this.dynamicForm.value;
    // Formateo los datos para no perder estructura original
    const formattedValues = this.formatValues(values);
    this.formValuesChange.emit(formattedValues); // Emisor de eventos a componente padre
  }

  /**
   * Formateo de datos del formulario a los requeridos
   * @param values
   * @returns
   */
  formatValues(values: any) {
    const formatted = {};
    for (const group of this.fieldGroups) {
      if (!formatted[this.rootObjectKey]) {
        formatted[this.rootObjectKey] = {}; // Objeto que engloba todos los demás (por ej, Residuos)
      }
      formatted[this.rootObjectKey][group.groupName] = {
        tipo: group.tipo,
      };
      for (const key in group.controls) {
        if (group.controls.hasOwnProperty(key)) {
          formatted[this.rootObjectKey][group.groupName][key] =
            values[group.controls[key]];
        }
      }
    }
    return formatted;
  }

  /**
   * Devuelve la clave del objeto pasado por argumentos
   * @param obj
   * @returns
   */
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  /**
   * Limpiar terminación "campo" y "&" de los títulos
   * @param key
   * @returns
   */
  removeCampoSuffix(key: string): string {
    if (key.includes('&')) {
      key = key.replace('&', ''); // Elimina el símbolo &
    }
    if (key.includes('_campo')) {
      key = key.split('_campo')[0]; // Elimina el sufijo '_campo'
    }
    return key.trim(); // Elimina cualquier espacio en blanco adicional
  }

  /**
   * Comprobar si el string key corresponde al campo por defecto
   * @param key
   */
  getDefaultField(key: string) {
    if (key.includes('$')) {
      return true;
    }
    return false;
  }
}
