import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-excel',
  templateUrl: './input-excel.component.html',
  styleUrls: ['./input-excel.component.scss'],
})
export class InputExcelComponent implements OnInit {
  @Input() type: string;
  @Input() label: string;
  @Input() preview?: boolean;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() required: boolean = false; // Agregar atributo requerido
  constructor(private datePipe: DatePipe) {}

  ngOnInit() {}

  onInputChange(event: any) {
    let formattedValue = event;
    if (this.type === 'date') {
      formattedValue = this.datePipe.transform(event, 'yyyy-MM-dd');
    }
    this.valueChange.emit(formattedValue);
    this.value = formattedValue; // Asegurarse de que el valor del modelo se actualice también
  }

  isValid(): boolean {
    return !(
      this.required &&
      (this.value === null || this.value === undefined || this.value === '')
    );
  }
}
