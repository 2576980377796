import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-options',
  templateUrl: './modal-options.component.html',
  styleUrls: ['./modal-options.component.scss'],
})
export class ModalOptionsComponent implements OnInit {
  @Input() show?: boolean;
  constructor() {}

  ngOnInit() {}
}
