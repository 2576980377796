<div>
  <ng-container [ngSwitch]="type">
    <p-calendar
      *ngSwitchCase="'date'"
      [(ngModel)]="value"
      placeholder="Seleccione una fecha"
      appendTo="body"
      [style]="{ width: '100%' }"
      [inputStyle]="{ width: '100%' }"
      (ngModelChange)="onInputChange($event)"
      class="mb-3 w-full"
      dateFormat="yy-mm-dd"
      [required]="required"
      [disabled]="preview ? true : false"
    ></p-calendar>
    <p-inputNumber
      placeholder="Introduce un valor numérico"
      *ngSwitchCase="'number'"
      [(ngModel)]="value"
      [style]="{ width: '100%' }"
      [inputStyle]="{ width: '100%' }"
      [maxFractionDigits]="6"
      (ngModelChange)="onInputChange($event)"
      class="mb-3 w-full"
      [required]="required"
      [disabled]="preview ? true : false"
    ></p-inputNumber>
    <input
      *ngSwitchDefault
      type="text"
      placeholder="Introduce un valor genérico"
      pInputText
      [style]="{ width: '100%' }"
      [inputStyle]="{ width: '100%' }"
      [(ngModel)]="value"
      (ngModelChange)="onInputChange($event)"
      class="mb-3 w-full"
      [required]="required"
      [disabled]="preview ? true : false"
    />
  </ng-container>
</div>
