<form [formGroup]="dynamicForm">
  @for (group of fieldGroups; track group) {
    <!-- No pintamos los inputs para los campos que tienen operaciones aritméticas -->
    @if(group && !(extractOperation(group.tipo) === 'SUMA' ||
      extractOperation(group.tipo) === 'RESTA' || extractOperation(group.tipo) ===
      'MULTIPLICACION' || extractOperation(group.tipo) === 'DIVISION' ) &&
      !getDefaultField(group.groupName)){
        <ion-grid class="p-5">
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12">
              <h3>{{ removeCampoSuffix(group.groupName) }}</h3>
            </ion-col>
          </ion-row>
          <ion-row>
            @for (subKey of objectKeys(group.controls); track subKey) {
              <ion-col size-xs="12" size-sm="12" size-md="6">
                <div>
                  <label>{{ subKey }}</label>
                  <div>
                    <ng-container [ngSwitch]="group.tipo">
                      <p-calendar
                        *ngSwitchCase="'date'"
                        placeholder="Seleccione una fecha"
                        [formControlName]="group.controls[subKey]"
                        appendTo="body"
                        [style]="{ width: '100%' }"
                        [inputStyle]="{ width: '100%' }"
                        class="mb-3 w-full"
                        dateFormat="yy-mm-dd"
                      ></p-calendar>
                      <p-inputNumber
                        [formControlName]="group.controls[subKey]"
                        placeholder="Introduce un valor numérico"
                        *ngSwitchCase="'number'"
                        [style]="{ width: '100%' }"
                        [inputStyle]="{ width: '100%' }"
                        [maxFractionDigits]="6"
                        class="mb-3 w-full"
                      ></p-inputNumber>
                      <input
                        *ngSwitchDefault
                        type="text"
                        [formControlName]="group.controls[subKey]"
                        placeholder="Introduce un valor genérico"
                        pInputText
                        [style]="{ width: '100%' }"
                        [inputStyle]="{ width: '100%' }"
                        class="mb-3 w-full"
                      />
                    </ng-container>
                  </div>
                </div>
              </ion-col>
            }
      </ion-row>
    </ion-grid>
    } 
  }
</form>
