<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
  <ion-row>
    <div class="header"></div>
  </ion-row>
  <ion-row style="margin-top: -6rem !important">
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <p-card>
        @if(loading){
        <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-end">
          <div class="spinner-container-indicator">
            <ion-spinner name="dots"></ion-spinner>
            <ion-label>Cargando...</ion-label>
          </div>
        </ion-col>
        } @else {
        <div slot="header" class="header-with-buttons">
          <h3>Resumen indicadores: doble materialidad</h3>
          <p-button
            label="Gestionar grupos"
            (click)="handleAddButton($event)"
          ></p-button>
        </div>

        <div class="w-full mb-1">
          @if(groupsWithDmData.length > 0){
          <ng-container
            *ngFor="let group of groupsWithDmData; trackBy: trackById"
          >
            <p-accordion class="accordion-indicator">
              <p-accordionTab [header]="group.name">
                <div
                  class="w-full flex justify-content-center align-items-center"
                >
                  <p-button
                    label="Mostrar matriz de riesgos"
                    icon="pi pi-eye"
                    (click)="openRiskMatrixDialog(group)"
                  ></p-button>
                  <p-button
                    pTooltip="Descargar informe"
                    icon="pi pi-download"
                    (click)="downloadRiskMatrixDocument()"
                  ></p-button>
                </div>
                <h3 class="text-left">Indicadores</h3>
                <p-table
                  #dt1
                  styleClass="p-datatable-striped"
                  responsiveLayout="scroll"
                  [value]="group['dual_materiality']"
                  [rowHover]="true"
                  [paginator]="true"
                  [rows]="3"
                  [globalFilterFields]="['name']"
                >
                  <ng-template pTemplate="caption">
                    <div
                      style="gap: 1vw"
                      class="flex justify-content-center align-items-center sm:flex-row"
                    >
                      <input
                        pInputText
                        type="text"
                        #filterGeneral
                        (input)="dt1.filterGlobal($event.target.value, 'contains')"
                        placeholder="Buscar registro"
                        class="w-full"
                      />
                      <p-button
                        icon="pi pi-plus"
                        pTooltip="Nuevo registro de doble materialidad"
                        (click)="showDualMaterialityFormDialog(group)"
                      ></p-button>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Indicador</th>
                      <th>Formulario</th>
                      <th>Fecha</th>
                      <th>Opciones</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-dm>
                    <tr>
                      <td>{{ dm.indicator?.name }}</td>
                      <td>
                        <p-tag
                          [severity]="dm.isComplete ? 'success' : 'warning'"
                          [value]="dm.isComplete ? 'Completo' : 'Incompleto'"
                        />
                      </td>
                      <td>{{ dm.created_at | date: 'dd/MM/YY HH:mm'}}</td>
                      <td>
                        <p-button
                          class="m-1"
                          [loading]="loadingModal"
                          pTooltip="Editar registro"
                          icon="pi pi-pencil"
                          (click)="showDualMaterialityFormDialog(group, dm)"
                        ></p-button>
                        <p-button
                          class="m-1"
                          severity="danger"
                          pTooltip="Eliminar registro"
                          icon="pi pi-trash"
                          (click)="deleteDm(dm)"
                        ></p-button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="7">No se encontraron registros</td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
            </p-accordion>
          </ng-container>
          } @else {
          <div
            class="p-4 w-full flex align-items-center justify-content-center text-center"
          >
            No existen grupos de doble materialidad
          </div>
          }
        </div>
        }
      </p-card>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Modal para cumplimentar doble materialidad -->
<p-dialog
  header="Doble materialidad"
  [(visible)]="dialogDualMaterialityForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="handleHideDualMateriality()"
>
  <p-stepper [(activeStep)]="stepperIndex">
    <!-- Paso 1 selección de grupo -->
    <p-stepperPanel header="Indicadores">
      <ng-template
        pTemplate="content"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <div class="">
          <ion-grid>
            <ion-row>
              <ion-col size-xs="12" size-sm="12" size-md="12">
                <label for="">*Indicadores</label>
                <div class="mt-1">
                  <p-listbox
                    [options]="indicators"
                    [(ngModel)]="selectedIndicators"
                    optionLabel="name"
                    optionValue="id"
                    [multiple]="true"
                    [filter]="true"
                    [disabled]="edit ? true : false"
                    [style]="{'width':'100%'}"
                    [listStyle]="{'height': '220px'}"
                  />
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div class="flex pt-4 justify-content-end">
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 1 -->
    <p-stepperPanel header="Paso 1">
      <ng-template
        pTemplate="content"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 1 -->
        <form [formGroup]="form1">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Comprensión del contexto: </b> este paso inicial implica un
                  análisis profundo del entorno en el que opera la empresa
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for=""> Regulaciones </label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mb-3 mt-1"
                    rows="5"
                    pInputTextarea
                    formControlName="regulations_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(1, 1)"
                    (onSelect)="onFileSelect($event, 1, 1)"
                    (onRemove)="deleteFileSelected($event,1, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[1] && existingFiles[1][1]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[1][1])"
                    ></p-button>
                  </div>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">
                    Actividades y relaciones comerciales
                    <i
                      class="pi pi-question-circle click-pointer"
                      (click)="op1.toggle($event)"
                    ></i>
                  </label>
                  <!-- Overlay -->
                  <p-overlayPanel #op1>
                    <div style="width: 40vw" class="flex flex-column">
                      <p>
                        Las actividades y relaciones comerciales relacionadas
                        con la <strong>NEIS 2 SBM-1</strong> se plantean en
                        términos de:
                      </p>
                      <ol>
                        <li>
                          El análisis del plan de negocio de la empresa, su
                          estrategia, sus estados financieros y, en su caso,
                          cualquier otra información facilitada a los
                          inversores;
                        </li>
                        <li>
                          Las actividades de la empresa, sus productos/servicios
                          y la localización geográfica de dichas actividades; y
                        </li>
                        <li>
                          El mapeo de las relaciones comerciales de la empresa y
                          aguas arriba y aguas abajo de la cadena de valor,
                          incluido el tipo y la naturaleza de las relaciones
                          comerciales.
                        </li>
                      </ol>
                    </div>
                  </p-overlayPanel>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    formControlName="business_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(1, 2)"
                    (onSelect)="onFileSelect($event, 1, 2)"
                    (onRemove)="deleteFileSelected($event, 1, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[1] && existingFiles[1][2]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[1][2])"
                    ></p-button>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">
                    Comprensión de las partes interesadas afectadas
                    <i
                      class="pi pi-question-circle click-pointer"
                      (click)="op2.toggle($event)"
                    ></i>
                  </label>
                  <!-- Overlay -->
                  <p-overlayPanel #op2>
                    <div style="width: 40vw" class="flex flex-column">
                      <p>
                        El objetivo consiste en comprender qué partes
                        interesadas se ven o pueden verse afectadas por las
                        operaciones propias de la empresa y aguas arriba y aguas
                        abajo de la cadena de valor. También incluye sus
                        opiniones e intereses (en consonancia con lo indicado en
                        la
                        <strong
                          >NEIS 2 SBM-2 Intereses y opiniones de las partes
                          interesadas</strong
                        >). A partir de ahí, la empresa puede identificar a las
                        principales partes interesadas. Para ayudar a esa
                        comprensión, se puede tener en cuenta lo siguiente:
                      </p>
                      <ul>
                        <li>
                          Un análisis de las iniciativas existentes de
                          colaboración de las partes interesadas (por ejemplo, a
                          través de los equipos de comunicación, relaciones con
                          los inversores, gestión empresarial, ventas y
                          compras); y
                        </li>
                        <li>
                          Un mapeo de las partes interesadas afectadas en todas
                          las actividades y relaciones comerciales de la
                          empresa. Se pueden identificar grupos independientes
                          de partes interesadas afectadas por actividad,
                          producto o servicio y se les debe dar prioridad con
                          respecto a una determinada cuestión de sostenibilidad.
                        </li>
                      </ul>
                    </div>
                  </p-overlayPanel>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="understanding_stakeholders_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event, 1, 3)"
                    [files]="getUploadedFiles(1, 3)"
                    (onRemove)="deleteFileSelected($event, 1, 3)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[1] && existingFiles[1][3]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[1][3])"
                    ></p-button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 2 -->
    <p-stepperPanel header="Paso 2">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 2 -->
        <form [formGroup]="form2">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b
                    >Identificación de los impactos, riesgos y oportunidades
                    reales y potenciales relacionados con cuestiones de
                    sostenibilidad: </b
                  >este paso implica la recopilación de datos y la consulta con
                  las partes interesadas para identificar los temas relevantes
                  <i
                    class="pi pi-question-circle click-pointer"
                    (click)="op3.toggle($event)"
                  ></i>
                </p>
              </div>
              <p-overlayPanel #op3>
                <div style="width: 40vw" class="flex flex-column">
                  <p>
                    En este paso, la empresa identifica los
                    <strong>impactos, riesgos y oportunidades (IRO)</strong>
                    reales y potenciales relacionados con cuestiones
                    medioambientales, sociales y de gobernanza en sus
                    operaciones propias y aguas arriba y aguas abajo de su
                    cadena de valor. El resultado será una «larga» lista de
                    impactos, riesgos y oportunidades para su posterior
                    evaluación y análisis en etapas posteriores.
                  </p>
                </div>
              </p-overlayPanel>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas internas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="internal_consultations_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(2, 1)"
                    (onSelect)="onFileSelect($event, 2, 1)"
                    (onRemove)="deleteFileSelected($event, 2, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[2] && existingFiles[2][1]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[2][1])"
                    ></p-button>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas externas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="external_consultations_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(2, 2)"
                    (onSelect)="onFileSelect($event, 2, 2)"
                    (onRemove)="deleteFileSelected($event, 2, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[2] && existingFiles[2][2]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[2][2])"
                    ></p-button>
                  </div>
                </ion-col>
                <!-- Tabla de impactos, riesgos y oportunidades -->
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <h3 class="text-left">Listado de impactos</h3>
                  <p-table
                    #dt1
                    styleClass="p-datatable-striped"
                    responsiveLayout="scroll"
                    [value]="impactRiskOportunity"
                    [rowHover]="true"
                    [paginator]="true"
                    [rows]="3"
                    [globalFilterFields]="['impact_text', 'risk_name', 'oportunity_text']"
                  >
                    <ng-template pTemplate="caption">
                      <div
                        style="gap: 1vw"
                        class="flex justify-content-center align-items-center sm:flex-row"
                      >
                        <input
                          pInputText
                          type="text"
                          #filterGeneral
                          (input)="dt1.filterGlobal($event.target.value, 'contains')"
                          placeholder="Buscar registro"
                          class="w-full"
                        />
                        <p-button
                          icon="pi pi-plus"
                          pTooltip="Nuevo registro"
                          (click)="showImpactRegisterDialog()"
                        ></p-button>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Impactos</th>
                        <th>Riesgos</th>
                        <th>Oportunidades</th>
                        <th>Opciones</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                      <tr>
                        <td>{{ item.impact_text }}</td>
                        <td>{{ item.risk_text }}</td>
                        <td>{{ item.opportunity_text }}</td>
                        <td>
                          <p-button
                            class="m-1"
                            pTooltip="Editar registro"
                            icon="pi pi-pencil"
                            (click)="showImpactRegisterDialog(item)"
                          ></p-button>
                          <p-button
                            class="m-1"
                            severity="danger"
                            pTooltip="Eliminar registro"
                            icon="pi pi-trash"
                            (click)="deleteImpact($event, item)"
                          ></p-button>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="7">No se encontraron registros</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ion-col>
                <!-- Fin tabla -->
              </ion-row>
            </ion-grid>
          </div>
        </form>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 3 -->
    <p-stepperPanel header="Paso 3">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <!-- Formulario paso 3 -->
        <form [formGroup]="form3">
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Evaluación de impacto y relevancia</b>
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Impacto</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="impact"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    rows="5"
                    pInputTextarea
                    formControlName="impact_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(3, 1)"
                    (onSelect)="onFileSelect($event, 3, 1)"
                    (onRemove)="deleteFileSelected($event, 3, 1)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[3] && existingFiles[3][1]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[3][1])"
                    ></p-button>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Materialidad de impacto</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="financial_relevance"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="financial_relevance_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    [files]="getUploadedFiles(3, 2)"
                    (onSelect)="onFileSelect($event, 3, 2)"
                    (onRemove)="deleteFileSelected($event, 3, 2)"
                    accept=".pdf"
                  ></p-fileUpload>
                  <div
                    class="button-container"
                    *ngIf="edit && existingFiles[3] && existingFiles[3][2]"
                  >
                    <p-button
                      icon="pi pi-file"
                      label="Abrir documento"
                      styleClass="w-full mt-2"
                      (click)="openDocument(existingFiles[3][2])"
                    ></p-button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </form>
        <div>
          <small
            >*Debe asignar un valor para impacto y relevancia financiera para
            que el indicador se muestre en la matriz de riesgos</small
          >
        </div>
        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 4 -->
    <p-stepperPanel header="Paso 4">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" class="step5-table">
              <div class="header-container">
                <p>Análisis de materialidad del
                  impacto
                  <i
                    class="pi pi-question-circle click-pointer"
                    (click)="op8.toggle($event)"
                  ></i>
                </p>
              </div>
              <p-overlayPanel #op8>
                <div style="width: 40vw" class="flex flex-column">
                  <ul>
                    <li>
                      Magnitud: gravedad del impacto (es decir, grado de vulneración del acceso a necesidades
vitales básicas o libertades como la educación, los medios de subsistencia, etc.)
                    </li>
                    <li>
                      Alcance: la amplitud del impacto (es decir, el número de personas afectadas o la extensión
de los daños medioambientales)
                    </li>
                    <li>
                      Carácter irremediable: medida en la que se puede remediar el impacto (p. ej., mediante
indemnización o restitución, si se puede devolver a las personas afectadas el
ejercicio del derecho en cuestión, etc.). La cuestión subyacente es si existen límites a la
capacidad de devolver el medio ambiente o a los afectados a una situación al menos
igual o equivalente a la que tenían antes del impacto negativo.
                    </li>
                  </ul>
                </div>
              </p-overlayPanel>
              <table class="impact-table mt-2">
                <thead>
                  <tr>
                    <th rowspan="2">Ilustración<br />Impacto negativo</th>
                    <th colspan="3">Evaluación de la gravedad</th>
                    <th rowspan="2">
                      ¿Se considera que el impacto es material?
                    </th>
                  </tr>
                  <tr>
                    <th>Magnitud</th>
                    <th>Alcance</th>
                    <th>Carácter irremediable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of upstreamDownstream; let i = index">
                    <td>{{ item.impact_risk_oportunity.impact_text }}</td>
                    <!-- Magnitud -->
                    <td
                      [ngClass]="getColorClass(item.magnitude)"
                      (click)="openOverlayStep5($event, 'magnitude', item, i)"
                    >
                      {{ item.magnitude }}
                    </td>
                    <!-- Alcance -->
                    <td
                      [ngClass]="getColorClass(item.scope)"
                      (click)="openOverlayStep5($event, 'scope', item, i)"
                    >
                      {{ item.scope }}
                    </td>
                    <!-- Irremediabilidad -->
                    <td
                      [ngClass]="getColorClass(item.irremediability)"
                      (click)="openOverlayStep5($event, 'irremediability', item, i)"
                    >
                      {{ item.irremediability }}
                    </td>
                    <!-- Materialidad -->
                    <td
                      (click)="openOverlayStep5($event, 'material_impact', item, i)"
                    >
                      {{ item.material_impact ? 'Sí' : 'No' }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- <div class="legend">
                <label>Código de colores:</label>
                <table class="color-code-table">
                  <tr>
                    <td class="low">1</td>
                    <td class="medium-low">2</td>
                    <td class="medium">3</td>
                    <td class="medium-high">4</td>
                    <td class="high">5</td>
                  </tr>
                </table>
              </div> -->
            </ion-col>
          </ion-row>

          <!-- Overlay Panel Global -->
          <p-overlayPanel #op>
            <div style="width: 15vw" class="flex flex-column">
              <ng-container
                *ngIf="selectedFieldStep5 === 'material_impact' && selectedItemStep5"
              >
                <p-dropdown
                  [options]="yesNoOptions"
                  [(ngModel)]="selectedItemStep5.material_impact"
                  (onChange)="updateValueStep5(op, selectedIndexStep5, selectedFieldStep5, selectedItemStep5.material_impact)"
                  placeholder="Seleccione una opción"
                ></p-dropdown>
              </ng-container>
              <ng-container
                *ngIf="selectedFieldStep5 !== 'material_impact' && selectedItemStep5"
              >
                <p-dropdown
                  [options]="numberScale"
                  [(ngModel)]="selectedItemStep5[selectedFieldStep6]"
                  (onChange)="updateValueStep5(op, selectedIndexStep5, selectedFieldStep5, selectedItemStep5[selectedFieldStep6])"
                  placeholder="Seleccione un valor"
                ></p-dropdown>
              </ng-container>
            </div>
          </p-overlayPanel>
        </ion-grid>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 5 -->
    <p-stepperPanel header="Paso 5">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" class="step5-table">
              <div class="header-container">
                <p>Análisis de materialidad financiera
                  <i
                    class="pi pi-question-circle click-pointer"
                    (click)="op9.toggle($event)"
                  ></i>
                </p>
              </div>
              <p-overlayPanel #op9>
                <div style="width: 40vw" class="flex flex-column">
                  <ul>
                    <li>
                      Magnitud: gravedad del impacto (es decir, grado de vulneración del acceso a necesidades
vitales básicas o libertades como la educación, los medios de subsistencia, etc.)
                    </li>
                    <li>
                      Alcance: la amplitud del impacto (es decir, el número de personas afectadas o la extensión
de los daños medioambientales)
                    </li>
                    <li>
                      Carácter irremediable: medida en la que se puede remediar el impacto (p. ej., mediante
indemnización o restitución, si se puede devolver a las personas afectadas el
ejercicio del derecho en cuestión, etc.). La cuestión subyacente es si existen límites a la
capacidad de devolver el medio ambiente o a los afectados a una situación al menos
igual o equivalente a la que tenían antes del impacto negativo.
                    </li>
                  </ul>
                </div>
              </p-overlayPanel>
              <table class="impact-table mt-2">
                <thead>
                  <tr>
                    <th rowspan="2">Ilustración<br />Impacto negativo</th>
                    <th colspan="3">Evaluación de la gravedad</th>
                    <th rowspan="2">
                      ¿Se considera que el impacto es material?
                    </th>
                  </tr>
                  <tr>
                    <th>Magnitud</th>
                    <th>Alcance</th>
                    <th>Carácter irremediable</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of financialMateriality; let i = index">
                    <td>{{ item.impact_risk_oportunity.impact_text }}</td>
                    <!-- Magnitud -->
                    <td
                      [ngClass]="getColorClass(item.magnitude)"
                      (click)="openOverlayStep6($event, 'magnitude', item, i)"
                    >
                      {{ item.magnitude }}
                    </td>
                    <!-- Alcance -->
                    <td
                      [ngClass]="getColorClass(item.scope)"
                      (click)="openOverlayStep6($event, 'scope', item, i)"
                    >
                      {{ item.scope }}
                    </td>
                    <!-- Irremediabilidad -->
                    <td
                      [ngClass]="getColorClass(item.irremediability)"
                      (click)="openOverlayStep6($event, 'irremediability', item, i)"
                    >
                      {{ item.irremediability }}
                    </td>
                    <!-- Materialidad -->
                    <td
                      (click)="openOverlayStep6($event, 'material_impact', item, i)"
                    >
                      {{ item.material_impact ? 'Sí' : 'No' }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- <div class="legend">
                <label>Código de colores:</label>
                <table class="color-code-table">
                  <tr>
                    <td class="low">1</td>
                    <td class="medium-low">2</td>
                    <td class="medium">3</td>
                    <td class="medium-high">4</td>
                    <td class="high">5</td>
                  </tr>
                </table>
              </div> -->
            </ion-col>
          </ion-row>

          <!-- Overlay Panel Global -->
          <p-overlayPanel #op>
            <div style="width: 15vw" class="flex flex-column">
              <ng-container
                *ngIf="selectedFieldStep6 === 'material_impact' && selectedItemStep6"
              >
                <p-dropdown
                  [options]="yesNoOptions"
                  [(ngModel)]="selectedItemStep6.material_impact"
                  (onChange)="updateValueStep6(op, selectedIndexStep6, selectedFieldStep6, selectedItemStep6.material_impact)"
                  placeholder="Seleccione una opción"
                ></p-dropdown>
              </ng-container>
              <ng-container
                *ngIf="selectedFieldStep6 !== 'material_impact' && selectedItemStep6"
              >
                <p-dropdown
                  [options]="numberScale"
                  [(ngModel)]="selectedItemStep6[selectedFieldStep6]"
                  (onChange)="updateValueStep6(op, selectedIndexStep6, selectedFieldStep6, selectedItemStep6[selectedFieldStep6])"
                  placeholder="Seleccione un valor"
                ></p-dropdown>
              </ng-container>
            </div>
          </p-overlayPanel>
        </ion-grid>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Siguiente"
            icon="pi pi-arrow-right"
            iconPos="right"
            (onClick)="nextCallback.emit()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>

    <!-- Paso 6 -->
    <p-stepperPanel header="Paso 6">
      <ng-template
        pTemplate="content"
        let-prevCallback="prevCallback"
        let-nextCallback="nextCallback"
        let-index="index"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12">
              <label for="">Título para apartado de información adicional</label>
              <input
                type="text"
                pInputText
                [style]="{'width':'100%'}"
                [(ngModel)]="formLabel"
              />
            </ion-col>
          </ion-row>
          <!-- Guardar campo del formulario -->
          <div class="save-form-field">
            <p-button
              (click)="updateStep7FormFields()"
              label="Añadir campo"
            ></p-button>
          </div>
        </ion-grid>

        <p-divider class="w-full" />

        <!-- Bucle de campos del formulario -->
        <ion-grid>
          <ion-row>
            @for(item of dynamicFormArrayStep7; track item){
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <label for="">
                {{ item.label }}
                <i
                  class="pi pi-times-circle icon-delete-field"
                  (click)="deleteFormField(item)"
                  pTooltip="Eliminar campo del formulario"
                ></i>
              </label>
              <textarea
                pInputTextarea
                class="w-full mb-3 mt-1"
                [style]="{'width':'100%'}"
                rows="5"
                [(ngModel)]="item.textarea"
              ></textarea>
            </ion-col>
            } @if (dynamicFormArrayStep7.length === 0) {
            <div class="flex w-full text-center justify-content-center p-2">
              No hay apartados adicionales
            </div>
            }
          </ion-row>
        </ion-grid>

        <div class="flex pt-4 justify-content-between">
          <p-button
            label="Atrás"
            icon="pi pi-arrow-left"
            (onClick)="prevCallback.emit()"
          />
          <p-button
            label="Guardar"
            iconPos="right"
            [loading]="loadingForm"
            (click)="onSubmitForm()"
          />
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</p-dialog>

<!-- Modal para selección de formulario -->
<p-dialog
  header="Seleccione una opción"
  [(visible)]="dialogForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40vw' }"
>
  <div class="p-4 align-items-center justify-content-center flex w-full gap-3">
    <p-button
      label="Doble materialidad a múltiples indicadores"
      (click)="formSelected(1)"
    ></p-button>
    <p-button
      label="Gestionar grupos para doble materialidad"
      (click)="formSelected(2)"
    ></p-button>
  </div>
</p-dialog>

<!-- Modal para historico de DM -->
<p-dialog
  header="Doble materialidad del indicador"
  [(visible)]="dialogDualMateriality"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '70vw' }"
>
</p-dialog>

<!-- Modal para gestionar grupos -->
<p-dialog
  header="Gestión de grupos para doble materialidad"
  [(visible)]="dialogGroup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
>
  <p-table
    #dt1
    styleClass="p-datatable-striped"
    responsiveLayout="scroll"
    [value]="dmGroups"
    [rowHover]="true"
    [paginator]="true"
    [rows]="5"
    [globalFilterFields]="['name']"
  >
    <ng-template pTemplate="caption">
      <div
        style="gap: 1vw"
        class="flex justify-content-center align-items-center sm:flex-row"
      >
        <input
          pInputText
          type="text"
          #filterGeneral
          (input)="dt1.filterGlobal($event.target.value, 'contains')"
          placeholder="Buscar grupo"
          class="w-full"
        />
        <p-button
          icon="pi pi-plus"
          pTooltip="Nuevo grupo"
          (click)="showDialog()"
        ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Nombre del grupo</th>
        <th>Opciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group>
      <tr>
        <td>{{ group.name }}</td>
        <td>
          <p-button
            class="m-1"
            pTooltip="Editar grupo"
            icon="pi pi-pencil"
            (click)="showDialog(group)"
          ></p-button>
          <p-button
            class="m-1"
            severity="danger"
            pTooltip="Eliminar grupo"
            icon="pi pi-trash"
            (click)="deleteGroup(group)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron grupos</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- Modal para crear/editar grupos -->
<p-dialog
  header="Gestión de grupos para doble materialidad"
  [(visible)]="dialogGroupForm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
>
  <form [formGroup]="formDMGroup">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">*Nombre del grupo</label>
          <input
            type="text"
            class="mb-3 w-full"
            [style]="{ width: '100%' }"
            pInputText
            formControlName="name"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  <ng-template pTemplate="footer">
    <p-button
      label="Guardar"
      [loading]="loadingForm"
      (click)="onSubmitGroupForm()"
    >
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal de eliminación de DM -->
<p-dialog
  header="Eliminar registro de doble materialidad"
  [(visible)]="dialogDeleteDm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="true"
>
  <div class="flex flex-column align-items-center text-center">
    <p class="mb-3">¿Está seguro de que desea eliminar este registro?</p>
    <p class="mb-4">
      Recuerde que esta acción no se puede deshacer y afectará los datos
      relacionados.
    </p>
    <div class="flex justify-content-center gap-3">
      <p-button
        [loading]="loadingModalBtn"
        label="Sí"
        icon="pi pi-check"
        (click)="onClickDeleteDm(true)"
      ></p-button>
      <p-button
        [loading]="loadingModalBtn"
        label="No"
        icon="pi pi-times"
        (click)="onClickDeleteDm(false)"
      ></p-button>
    </div>
  </div>
</p-dialog>

<!-- Modal de eliminación de grupo DM -->
<p-dialog
  header="Eliminar grupo de doble materialidad"
  [(visible)]="dialogDeleteGroup"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="true"
>
  <div class="flex flex-column align-items-center text-center">
    <p class="mb-3">¿Está seguro de que desea eliminar este grupo?</p>
    <p class="mb-4">
      Recuerde que esta acción no se puede deshacer y afectará a todos los
      indicadores del grupo y sus datos
    </p>
    <div class="flex justify-content-center gap-3">
      <p-button
        [loading]="loadingModalBtn"
        label="Sí"
        icon="pi pi-check"
        (click)="onClickDeleteGroup(true)"
      ></p-button>
      <p-button
        [loading]="loadingModalBtn"
        label="No"
        icon="pi pi-times"
        (click)="onClickDeleteGroup(false)"
      ></p-button>
    </div>
  </div>
</p-dialog>

<!-- Modal para matriz de riesgos de un grupo -->
<p-dialog
  header="Matriz de riesgos"
  [(visible)]="dialogRiskMatrix"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100vw', height: '100vh'}"
>
  <div class="table-container">
    <div class="vertical-header">Materialidad de impacto</div>

    <div class="horizontal-header-container">
      <!-- Encabezado horizontal -->
      <div class="horizontal-header">Materialidad financiera</div>

      <div class="scrollable-table">
        <table class="risk-matrix-table">
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let impact of impacts">{{ impact }}</th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let financialRelevance of financialRelevances; let rowIndex = index"
            >
              <td class="header-cell" style="width: 30px !important;">{{ financialRelevance }}</td>
              <td
                *ngFor="let impact of impacts; let colIndex = index"
                [ngClass]="getCellClass(rowIndex, colIndex)"
              >
                <div class="chip-container">
                  <div
                    class="chip"
                    *ngFor="
                      let value of
                      matrixGroupData?.matrixData[financialRelevance]?.[impact] || []
                    "
                  >
                    <p-chip
                      [icon]="value?.parent_icon"
                      (click)="showIndicatorsByChip(value)"
                      label="{{ value?.parent_label }}"
                      styleClass="custom-chip"
                      class="click-pointer"
                    ></p-chip>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</p-dialog>

<!-- Modal para mostrar indicadores de un grupo -->
<p-dialog
  header="Indicadores incluidos"
  [(visible)]="dialogMatrixClicked"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40vw'}"
  (onHide)="indicatorsByDmGroupClicked = null"
>
  @if(indicatorsByDmGroupClicked){
  <p-dataView
    #dv
    [value]="indicatorsByDmGroupClicked['indicatorsData']"
    [rows]="5"
    [paginator]="true"
  >
    <ng-template pTemplate="list" let-items>
      <div class="grid grid-nogutter">
        <div
          class="col-12"
          *ngFor="let item of items; let first = first"
          class="col-12"
        >
          <span>{{ item?.name }}</span>
        </div>
      </div>
    </ng-template>
  </p-dataView>
  }
</p-dialog>

<!-- Modal para creación/edición de registro de impacto -->
<p-dialog
  header="{{ editImpact ? 'Edición de registro' : 'Creación de registro'}}"
  [(visible)]="dialogImpactRisk"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw'}"
  (onHide)="handleCloseImpactDialog()"
>
  <form [formGroup]="formImpact">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="">Impacto</label>
          <textarea
            class="w-full mb-3 mt-1"
            formControlName="impact_text"
            pInputTextarea
            rows="5"
          ></textarea>
          <p-fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            accept=".pdf"
          ></p-fileUpload>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="">Riesgo</label>
          <textarea
            class="w-full mb-3 mt-1"
            formControlName="risk_text"
            pInputTextarea
            rows="5"
          ></textarea>
          <p-fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            accept=".pdf"
          ></p-fileUpload>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label for="">Oportunidad</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            formControlName="opportunity_text"
            rows="5"
          ></textarea>
          <p-fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            accept=".pdf"
          ></p-fileUpload>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <ng-template pTemplate="footer">
    <p-button label="Guardar"></p-button>
  </ng-template>
</p-dialog>
